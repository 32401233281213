import React from 'react'
import {
  BrowserRouter,
  unstable_HistoryRouter as HistoryRouter,
  Route,
  Routes
} from 'react-router-dom'
import ScrollToTop from './ScrollToTop'

import {useRecoilState, useRecoilValue} from 'recoil'

/*************
 LayOut
 *************/

import DefaultLayout from '../layout/DefaultLayout'

/*************
 Auth
 *************/

import AuthRoute from './AuthRoute'

/*************
 public
 *************/

import NotFound from '../page/NotFound'

/*************
 auth
 *************/

import Index from '../page/'

const Routers = () => {

  const publicRoutes = [
    {name: 'Index', path: '/*', element: Index}
  ]

  return (
    <Routes>
      {publicRoutes.map((route, key) => (
        <Route
          key={key}
          path={route.path}
          element={<route.element/>}
        />
      ))}
    </Routes>
  )
}

export default Routers
