import React from 'react'
import Router from '../src/route/'

function App() {
  return (
    <Router />
  )
}

export default App
